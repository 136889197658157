import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

export const useStateWithLocalStorage = (localStorageKey, defaultValue, json = false) => {
  // Value will be null until actually loaded from storage
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    let initialValue = localStorageKey != null ? localStorage.getItem(localStorageKey) : null;

    if (json && defaultValue != null) {
      try {
        initialValue = JSON.parse(initialValue);
      }
      catch (e) {
        Sentry.captureException(
          new Error(`useStateWithLocalStorage - Could not parse JSON session data for key "${localStorageKey}": "${initialValue}"`),
        );
      }
    }

    if (initialValue == null) {
      initialValue = defaultValue;
    }

    setValue(initialValue);
  }, []);

  React.useEffect(() => {
    let savedValue = value;

    if (json) {
      savedValue = JSON.stringify(savedValue);
    }

    localStorage.setItem(localStorageKey, savedValue);
  }, [value]);

  return [value, setValue];
};

export const useStateWithSessionStorage = (sessionStorageKey, defaultValue, json = false) => {
  // Value will be null until actually loaded from storage
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    let initialValue = sessionStorageKey != null ? sessionStorage.getItem(sessionStorageKey) : null;

    if (json && initialValue != null) {
      try {
        initialValue = JSON.parse(initialValue);
      }
      catch (e) {
        Sentry.captureException(
          new Error(`useStateWithSessionStorage - Could not parse JSON session data: ${initialValue}`),
        );
      }
    }

    if (initialValue == null) {
      initialValue = defaultValue;
    }

    setValue(initialValue);
  }, []);

  React.useEffect(() => {
    let savedValue = value;

    if (json) {
      savedValue = JSON.stringify(savedValue);
    }

    sessionStorage.setItem(sessionStorageKey, savedValue);
  }, [value]);

  return [value, setValue];
};