import classNames from 'classnames';
import Link from 'next/link'
import { useRouter } from 'next/router';
import { Avatar, Backdrop, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip, ListItemAvatar, Menu, MenuItem } from '@mui/material/';
import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from 'components/sidebar/sidebar.module.scss'

import Icon from 'components/icon';
import { GetPermissions, Logout } from 'lib/session';
import { getDocumentCookie } from 'src/helpers';
import { computeColor } from 'lib/color';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import React from 'react';
import { GetUser } from 'lib/me';

function Sidebar({
  collapsed = false,
  mobileVisible = false,
  setCollapsed = null,
  setMobileVisible = null,
  updatePermissionsCounter = 0,
  updatePermissions,
  responseHandler,
  setUserDefaultLang,
}) {
  const router = useRouter();
  const { t } = useTranslation('sidebar');

  const [userIsOwner, setUserIsOwner] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [tenantRole, setTenantRole] = useState(null);
  const [tenantOrganization, setTenantOrganization] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [apiAccessLevel, setApiAccessLevel] = useState(0);

  // Check permissions on page change
  // TODO - Probably should set these up at a higher level with a state
  useEffect(() => {
    setUserIsLoggedIn(getDocumentCookie('session_id', null) != null);
    setUserIsOwner(getDocumentCookie('user_is_owner', '0') == '1');
    setUserFirstName(getDocumentCookie('user_first_name', ''));
    setUserLastName(getDocumentCookie('user_last_name', ''));
    setTenantOrganization(getDocumentCookie('tenant_organization', false));
    setApiAccessLevel(getDocumentCookie('api_access_level', 0));
    setTenantRole(getDocumentCookie('tenant_role', null));
  }, [updatePermissionsCounter]);

  const cn = classNames(
    styles.sidebar,
    {
      [styles.collapsed]: collapsed,
      [styles.mobileHidden]: !mobileVisible,
    }
  );

  // Handle logout
  const handleLogout = () => {
    setUserMenuAnchorEl(null);

    Logout().then((response) => {
      // If we have a session_id when logging out, it's because we were using "login as user"
      if (typeof (response.session_id) != 'undefined' && response.session_id) {
        GetPermissions().then(response => {
          GetUser({ responseHandler }).then(response => {
            setUserDefaultLang(response.default_lang);

            updatePermissions();

            router.push('/');
          });
        })
      }
      else {
        updatePermissions();
        router.push('/login');
      }
    });
  }

  // User menu click
  const handleUserMenuClick = () => {
    setMobileVisible(false);
    setUserMenuAnchorEl(null);
  };

  // Avatar
  let userInitials = '';
  userInitials += (userFirstName) ? userFirstName[0].toUpperCase() : '';
  userInitials += (userLastName) ? userLastName[0].toUpperCase() : '';

  const userColor = computeColor(userFirstName + userLastName);

  const avatarProps = {
    sx: {
      backgroundColor: userColor.background_color,
      color: userColor.text_color,
      fontSize: 16,
      height: 30,
      width: 30,
    },
    children: userInitials,
    variant: 'rounded',
  }

  const userLastNameAbbr = (userLastName) ? userLastName[0] : '';
  const fullUserName = `${userFirstName || ''} ${userLastNameAbbr || ''}`;

  // Toggler left / right
  let togglerIcon;
  if (collapsed) {
    togglerIcon = (
      <Icon name="chevron_right" ellipsis={true} className={styles.icon} />
    );
  }
  else {
    togglerIcon = (
      <Icon name="chevron_left" ellipsis={true} className={styles.icon} />
    );
  }

  return (
    <>
      <Drawer
        variant="permanent"
        className={cn}
        open={false}
      >

        <List component={Box} className={styles.list}>
          {userIsLoggedIn &&
            <Tooltip title={(collapsed) ? fullUserName : ''} placement="right">
              <ListItem
                button
                className={styles.element}
                onClick={e => {
                  setUserMenuAnchorEl(e.currentTarget);
                }}
              >
                <ListItemAvatar className={styles.elementIconWrapper}>
                  <Avatar {...avatarProps} />
                </ListItemAvatar>
                <ListItemText
                  primary={fullUserName}
                  secondary={tenantOrganization}
                  className={styles.linkText}
                />
                {userMenuAnchorEl != null ? <ExpandLess className={styles.expandIcon} /> : <ExpandMore className={styles.expandIcon} />}

              </ListItem>
            </Tooltip>
          }

          <Menu
            open={userMenuAnchorEl != null}
            onClose={() => {
              setUserMenuAnchorEl(null);
            }}
            anchorEl={userMenuAnchorEl}
          >
            <Link href="/account">
              <a>
                <MenuItem onClick={handleUserMenuClick}>{t('account')}</MenuItem>
              </a>
            </Link>

            {apiAccessLevel > 0 &&
              <Link href="/account/api">
                <a>
                  <MenuItem onClick={handleUserMenuClick}>{t('api')}</MenuItem>
                </a>
              </Link>
            }

            <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
          </Menu>

          <Box className="mb4"></Box>

          <Link href="/">
            <a>
              <Tooltip title={(collapsed) ? t('dashboard') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="dashboard" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('dashboard')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          <Link href="/recipes">
            <a>
              <Tooltip title={(collapsed) ? t('recipes') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="ramen_dining" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('recipes')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          <Link href="/ingredients">
            <a>
              <Tooltip title={(collapsed) ? t('ingredients') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="set_meal" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('ingredients')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          <Link href="/ingredients-database">
            <a>
              <Tooltip title={(collapsed) ? t('ingredients_db') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="list_alt" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('ingredients_db')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          <Divider className="my2" />

          {tenantRole == 'admin' &&
            <Link href="/admin">
              <a>
                <Tooltip title={(collapsed) ? 'Admin' : ''} placement="right">
                  <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                    <ListItemIcon className={styles.elementIconWrapper}>
                      <Icon name="admin_panel_settings" className={styles.icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography primary="Admin" className={styles.linkText} />
                  </ListItem>
                </Tooltip>
              </a>
            </Link>
          }

          {userIsOwner && tenantRole != 'admin' && tenantRole != 'system' &&
            <Link href="/billing">
              <a>
                <Tooltip title={(collapsed) ? t('billing') : ''} placement="right">
                  <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                    <ListItemIcon className={styles.elementIconWrapper}>
                      <Icon name="credit_card" className={styles.icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography primary={t('billing')} className={styles.linkText} />
                  </ListItem>
                </Tooltip>
              </a>
            </Link>
          }

          <Link href="/tags">
            <a>
              <Tooltip title={(collapsed) ? t('manage_tags') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="local_offer" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('manage_tags')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          {userIsOwner &&
            <Link href="/access">
              <a>
                <Tooltip title={(collapsed) ? t('access') : ''} placement="right">
                  <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                    <ListItemIcon className={styles.elementIconWrapper}>
                      <Icon name="groups" className={styles.icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography primary={t('access')} className={styles.linkText} />
                  </ListItem>
                </Tooltip>
              </a>
            </Link>
          }

          <Link href="/help">
            <a>
              <Tooltip title={(collapsed) ? t('help') : ''} placement="right">
                <ListItem button className={styles.element} onClick={handleUserMenuClick}>
                  <ListItemIcon className={styles.elementIconWrapper}>
                    <Icon name="help" className={styles.icon} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t('help')} className={styles.linkText} />
                </ListItem>
              </Tooltip>
            </a>
          </Link>

          <Tooltip title={(collapsed) ? t('toggle') : ''} placement="right">
            <ListItem button className={`${styles.element} ${styles.toggler}`} onClick={() => {
              if (typeof (setCollapsed) == 'function') {
                setCollapsed(!collapsed);
              }
            }}>
              <ListItemIcon className={styles.elementIconWrapper}>
                {togglerIcon}
              </ListItemIcon>
              <ListItemText disableTypography primary={t('collapse')} className={styles.linkText} />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer >

      <Backdrop
        className={styles.backdrop}
        open={mobileVisible}
        onClick={() => {
          if (typeof (setMobileVisible) == 'function') {
            setMobileVisible(false);
          }
        }}
      />
    </>
  );
}

export default Sidebar;