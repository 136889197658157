import { getDocumentCookie, setDocumentCookie } from "src/helpers";
import * as Sentry from '@sentry/nextjs';

export default async function RequestApi({
  url,
  body = {},
  method = 'GET',
  sessionId = null,
  multipart = false,
  jsonResponse = true,
  responseHandler = null,
  useTimeout = true,
}) {
  const finalUrl = new URL(process.env.NEXT_PUBLIC_API_URL + url);

  if (sessionId == null && typeof document != 'undefined') {
    sessionId = getDocumentCookie('session_id') || null;
  }

  body = cleanBody(body);
  body = nullIfBlankBody(body);

  let headers = {
    'Accept': 'application/json',
  };

  if (!multipart) {
    headers['Content-Type'] = 'application/json';
  }

  if (sessionId !== null) {
    headers['Authorization'] = sessionId;
  }

  let settings = {
    method: method,
    headers: headers,
  };

  if (method == 'GET') {
    finalUrl.search = new URLSearchParams(body).toString();
  }
  else if (multipart) {
    const formData = new FormData();

    for (let i in body) {
      formData.append(i, body[i]);
    }

    settings.body = formData;
  }
  else {
    settings.body = JSON.stringify(body);
  }

  let response = null;
  let isTimeout = false;
  let error = false;
  let timeoutId;

  // Use an abort controller to control and detect timeouts
  if (useTimeout) {
    const abortController = new AbortController();
    settings.signal = abortController.signal;

    timeoutId = setTimeout(() => abortController.abort(), 15000);
  }

  // Need to catch for Abort Controller (timeouts)
  try {
    response = await fetch(finalUrl, settings);
  }
  catch (err) {
    error = err;
  }

  if (error !== false) {
    // We're handling timeouts, but we want everything else to be raised normally
    if (error.name != 'AbortError') {
      throw (error);
    }
    // We're managing timeouts, but we still want them to appear in Sentry
    else {
      Sentry.captureException(error);
      isTimeout = true;
    }
  }

  if (useTimeout) {
    clearTimeout(timeoutId);
  }

  let data = null;

  if (response !== null && jsonResponse) {
    if (method == 'DELETE') {
      data = response.status == 204; // 204 No content = deleted successfully
    }
    else {
      data = await response.json();
    }

    if (typeof (responseHandler) == 'function') {
      responseHandler(response, data, isTimeout);
    }

    // Always save/update the session_id if in response
    if (typeof (data.session_id) !== 'undefined') {
      // Save as a cookie
      if (typeof (document) != 'undefined') {
        setDocumentCookie('session_id', data.session_id);
      }
    }
  }
  else {
    if (typeof (responseHandler) == 'function') {
      responseHandler(response, {}, isTimeout);
    }
  }

  return [data, response];
}

function cleanBody(body) {
  return Object.fromEntries(Object.entries(body).filter(([_, v]) => v != null));
}

function nullIfBlankBody(body) {
  if (!(body instanceof Object)) {
    return body;
  }

  let i;

  for (i in body) {
    if (body[i] instanceof Object) {
      body[i] = nullIfBlankBody(body[i]);
    }
    else if (body[i] === '') {
      body[i] = null;
    }
  }

  return body;
}
