import { Box, Link, Stack } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import React from "react";

export default function Footer() {
  const { t } = useTranslation('common');

  return (
    <Box className="appFooter">
      <div className="leftPart">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <Link href={t('footer_privacy_href')}>{t('footer_privacy')}</Link>
          <Link href={t('footer_terms_href')}>{t('footer_terms')}</Link>
        </Stack>
      </div>

      <Link rel={t('footer_website_href')}>
        <img src={'/images/labelify.png'} alt="Labelify" className="footerLogo" />
      </Link>

      <div className="rightPart">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <Link href={t('footer_website_href')}>{t('footer_website')}</Link>
          <Link href="mailto:info@labelify.ca">{t('footer_contact')}</Link>
        </Stack>
      </div>
    </Box>
  );
}