export const colors = [
  { 'color': 'Pink', 'background_color': '#e91e63', 'text_color': 'white' },
  { 'color': 'Violet', 'background_color': '#9c27b0', 'text_color': 'white' },
  { 'color': 'Purple', 'background_color': '#673ab7', 'text_color': 'white' },
  { 'color': 'Navy blue', 'background_color': '#3f51b5', 'text_color': 'white' },
  { 'color': 'Blue', 'background_color': '#2196f3', 'text_color': 'white' },
  { 'color': 'Electric blue', 'background_color': '#03a9f4', 'text_color': 'white' },
  { 'color': 'Aqua', 'background_color': '#00bcd4', 'text_color': 'white' },
  { 'color': 'Teal', 'background_color': '#009688', 'text_color': 'white' },
  { 'color': 'Green', 'background_color': '#4caf50', 'text_color': 'white' },
  { 'color': 'Bright green', 'background_color': '#8bc34a', 'text_color': 'white' },
  { 'color': 'Lime', 'background_color': '#cddc39', 'text_color': 'black' },
  { 'color': 'Yellow', 'background_color': '#ffeb3b', 'text_color': 'black' },
  { 'color': 'Orange', 'background_color': '#ffc107', 'text_color': 'white' },
  { 'color': 'Tangerine', 'background_color': '#ff9800', 'text_color': 'white' },
  { 'color': 'Salmon', 'background_color': '#ff5722', 'text_color': 'white' },
  { 'color': 'Red', 'background_color': '#ef2000', 'text_color': 'white' },
]

/**
 * Pick a consistant color for a given string
 * @param str string 
 */
export function computeColor(string) {
  if (typeof string != 'string') {
    return false;
  }

  let running_sum = 0;
  for (let i = 0; i < string.length; i++) {
    running_sum += string.charCodeAt(i);
  }

  let result = colors[running_sum % colors.length]

  return result;
}

/**
 * Get text color from background color
 * @param bg string
 */
export function textColorForBackground(bg) {
  let i;

  for (i in colors) {
    if (colors[i].background_color == bg) {
      return colors[i].text_color;
    }
  }

  return 'black';
}
