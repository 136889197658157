import RequestApi from 'lib/labelify-api';

/**
 * Get user
 */
export async function GetUser({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'me',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Edit user
 * @param {object} data 
 */
export async function EditUser({ data, sessionId, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me',
    body: {
      first_name: data.first_name ?? null,
      last_name: data.last_name ?? null,
      company: data.company ?? null,
      telephone: data.telephone ?? null,
      default_lang: data.default_lang ?? null,
      newsletter: data.newsletter ?? null,
    },
    method: 'POST',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Change password
 */
export async function ChangePassword({ currentPassword, newPassword, sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me/password',
    body: {
      current_password: currentPassword,
      new_password: newPassword,
    },
    method: 'POST',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Change email
 */
export async function ChangeEmail({ password, email, sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me/email',
    body: {
      password: password,
      email: email,
    },
    method: 'POST',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get organization
 */
export async function GetOrganization({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'me/organization',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Edit organization
 * @param {object} data 
 */
export async function EditOrganization({ data, sessionId, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me/organization',
    body: {
      organization: data.organization ?? null,
    },
    method: 'POST',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get API key
 */
export async function GetApiKey({ sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me/api_key',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Regenerate API key
 */
export async function RegenerateApiKey({ sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'me/api_key',
    method: 'POST',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}
