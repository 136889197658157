import { setDocumentCookie } from 'src/helpers';
import RequestApi from 'lib/labelify-api';

/**
 * Login
 */
export async function Login({ email, password, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'session/login',
    body: {
      email: email,
      password: password
    },
    method: 'POST',
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData.session_id : null;
}

/**
 * Logout
 */
export async function Logout({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'session/logout',
    method: 'POST',
    sessionId,
    responseHandler,
  })

  if (typeof (document) != 'undefined') {
    setDocumentCookie('api_access_level', '0');
    setDocumentCookie('label_access_level', '0');
    setDocumentCookie('tenant_active', '0');
    setDocumentCookie('tenant_id', null);
    setDocumentCookie('tenant_in_trial', '0');
    setDocumentCookie('tenant_in_academic', '0');
    setDocumentCookie('tenant_role', null);
    setDocumentCookie('user_id', null);
    setDocumentCookie('user_is_owner', '0');
    setDocumentCookie('user_role', null);
    setDocumentCookie('user_first_name', null);
    setDocumentCookie('user_last_name', null);
    setDocumentCookie('tenant_organization', null);
    setDocumentCookie('tenant_limited_end_date', null);
    setDocumentCookie('limit_user', null);
    sessionStorage.clear();
    localStorage.clear();
  }

  if (typeof (responseData.session_id) === 'undefined' || !responseData.session_id) {
    setDocumentCookie('session_id', null);
  }

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Register
 * @param {object} data 
 */
export async function Register(data) {
  const [responseData, response] = await RequestApi({
    url: 'session/register',
    body: {
      email: data.email ?? null,
      password: data.password ?? null,
      first_name: data.first_name ?? null,
      last_name: data.last_name ?? null,
      company: data.company ?? null,
      telephone: data.telephone ?? null,
      default_lang: data.default_lang ?? null,
      consent: data.consent ?? null,
      newsletter: data.newsletter ?? null,
      source: data.source ?? null,
      country: data.country ?? null,
      region: data.region ?? null,
      st_number: data.st_number ?? null,
      customer_coupon: data.customer_coupon ?? null,
      plan_id: data.plan_id ?? null,
      subscription_coupon: data.subscription_coupon ?? null,
    },
    method: 'POST',
    ...data
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Setup intent
 */
export async function SetupIntent({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'session/setup_intent',
    sessionId,
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get quote
 * @param {string} quoteID 
 */
export async function GetQuote(quoteID) {
  const [responseData, response] = await RequestApi({
    url: 'session/quote/' + quoteID,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Quote
 * @param {object} data 
 */
export async function Quote(data) {
  const [responseData, response] = await RequestApi({
    url: 'session/quote',
    body: {
      plan_id: data.plan_id ?? null,
      subscription_coupon: data.subscription_coupon ?? null,
    },
    method: 'POST',
    ...data
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Complete quote
 * @param {object} data 
 */
export async function CompleteSubscription({ plan_id = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'session/complete_subscription',
    body: {
      plan_id: plan_id
    },
    method: 'POST',
    responseHandler
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Register trial
 * @param {object} data 
 */
export async function RegisterTrial(data) {
  const [responseData, response] = await RequestApi({
    url: 'session/register_trial',
    body: {
      email: data.email ?? null,
      password: data.password ?? null,
      first_name: data.first_name ?? null,
      last_name: data.last_name ?? null,
      company: data.company ?? null,
      telephone: data.telephone ?? null,
      default_lang: data.default_lang ?? null,
      consent: data.consent ?? null,
      newsletter: data.newsletter ?? null,
      country: data.country ?? null,
      region: data.region ?? null,
      st_number: data.st_number ?? null,
      customer_coupon: data.customer_coupon ?? null,
    },
    method: 'POST',
    ...data
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Complete invitation
 * @param {object} data 
 */
export async function CompleteInvitation(data = {}) {
  const [responseData, response] = await RequestApi({
    url: 'session/complete_invitation',
    body: {
      token: data.token ?? null,
      email: data.email ?? null,
      password: data.password ?? null,
      first_name: data.first_name ?? null,
      last_name: data.last_name ?? null,
      company: data.company ?? null,
      telephone: data.telephone ?? null,
      default_lang: data.default_lang ?? null,
      consent: data.consent ?? null,
      newsletter: data.newsletter ?? null,
    },
    method: 'POST',
    ...data
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Request forgot password
 * @param {string} email 
 */
export async function RequestForgotPassword(email, responseHandler = null) {
  const [responseData, response] = await RequestApi({
    url: 'session/request_forgot_password',
    body: {
      email: email,
    },
    method: 'POST',
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData.success : false;
}

/**
 * Change forgot password
 * @param {string} password 
 * @param {string} token 
 */
export async function ChangeForgotPassword(password, token, responseHandler = null) {
  const [responseData, response] = await RequestApi({
    url: 'session/change_forgot_password',
    body: {
      password: password,
      token: token,
    },
    method: 'POST',
    responseHandler,
  })

  return (response !== null && response.status == 200) ? responseData.success : false;
}

/**
 * Get permissions
 */
export async function GetPermissions({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'session/permissions',
    sessionId,
    responseHandler,
  });

  if (typeof (responseData.user_id) !== 'undefined') {
    if (typeof (document) != 'undefined') {
      setDocumentCookie('api_access_level', responseData.api_access_level);
      setDocumentCookie('label_access_level', responseData.label_access_level);
      // This one is sent as a string at the moment
      setDocumentCookie('tenant_active', (responseData.tenant_active == 'True' || responseData.tenant_active == true) ? "1" : "0");
      setDocumentCookie('tenant_id', responseData.tenant_id);
      setDocumentCookie('tenant_in_trial', (responseData.tenant_in_trial) ? "1" : "0");
      setDocumentCookie('tenant_in_academic', (responseData.tenant_in_academic) ? "1" : "0");
      setDocumentCookie('tenant_role', responseData.tenant_role);
      setDocumentCookie('user_id', responseData.user_id);
      setDocumentCookie('user_is_owner', (responseData.user_is_owner) ? "1" : "0");
      setDocumentCookie('user_role', responseData.user_role);
      setDocumentCookie('user_first_name', responseData.user_first_name);
      setDocumentCookie('user_last_name', responseData.user_last_name);
      setDocumentCookie('tenant_organization', responseData.tenant_organization);
      setDocumentCookie('tenant_limited_end_date', responseData.tenant_limited_end_date);
      setDocumentCookie('limit_user', responseData.limit_user);
      setDocumentCookie('status', responseData.status);
    }
  }

  return (response !== null && response.status == 200) ? responseData : false;
} 
