import React from 'react';
import useTranslation from "next-translate/useTranslation";

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (typeof (target) === 'object' && typeof (source) === 'object') {
    for (const key in source) {
      // Watch out, "null" is an object
      if (typeof (source[key]) === 'object' && source[key] !== null) {
        if (!target[key]) {
          if (Array.isArray(source[key])) {
            Object.assign(target, { [key]: [] });
          }
          else {
            Object.assign(target, { [key]: {} });
          }
        }
        mergeDeep(target[key], source[key]);
      }
      else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Format currency
 * @param {*} amountCents 
 * @param {*} lang 
 * @param {*} showCents 
 * @returns jsx
 */
export function formatCurrency(amountCents, lang = 'en', showCents = true) {
  const amountStr = String((amountCents < 0) ? -amountCents : amountCents);
  const dollars = amountStr.substring(0, amountStr.length - 2) || '0';
  const cents = amountStr.substring(amountStr.length - 2);
  const negative = (amountCents < 0) ? '-' : ''; // Negative in front of dollar sign

  let final = <></>;

  if (lang == 'fr') {
    final = (<span>{negative}{wordWrap(dollars, 3, ' ')}</span>);

    if (showCents) {
      final += (<><span className="cents_separator">,</span><span className="cents">{cents.padStart(2, '0')}</span></>);
    }

    final += (<span>&nbsp;$</span>);
  }
  else {
    final = (<>
      <span>{negative}$</span>
      <span>{wordWrap(dollars, 3, ',')}</span>
      {(showCents) ? <>
        <span className="cents_separator">.</span>
        <span className="cents">{cents.padStart(2, '0')}</span>
      </> : ''}
    </>);
  }

  return final;
}

/**
 * Word wrap
 * @param {*} text 
 * @param {*} width 
 * @param {*} char 
 * @returns string
 */
export function wordWrap(text, width, char) {
  var re = new RegExp("([\\w\\s]{" + (width - 2) + ",}?\\w)\\s?\\b", "g");
  var trim_re = new RegExp(char + "$", "g");

  return text.replace(re, `$1${char}`).replace(trim_re, '');
}

/**
 * Get document cookie
 * Client-side cookies
 * @param {string} key 
 * @returns string
 */
export function getDocumentCookie(key, defaultValue = null) {
  const cookies = document.cookie.split('; ');

  const cookie = cookies.find(row => row.startsWith(key + '='));

  let value = defaultValue;

  if (typeof cookie != 'undefined') {
    value = cookie.split('=')[1];
  }

  if (value == 'null') {
    value = null;
  }

  return value;
}

/**
 * Set document cookie
 * Client-side cookies
 * @param {string} key 
 * @param {string} value 
 * @returns string
 */
export function setDocumentCookie(key, value) {
  if (typeof document == 'undefined' || document == null) {
    return;
  }

  const date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days
  let expires = 'expires=' + date.toUTCString();

  document.cookie = key + '=' + value + ';' + expires + '; Secure; SameSite=Strict; path=/'
}
/**
 * UC first
 * @param {*} string 
 * @returns 
 */
export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Last seen
 * Should only be used for admin panel
 */
export function lastSeen(timeValue) {
  const now = Date.now();
  const lastSeen = Date.parse(timeValue);

  // getTimezoneOffset returns in minutes
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const diff = (now + timezoneOffset - lastSeen) / 1000 / 60;

  let text = '';

  if (diff > 1440) {
    const days = Math.floor(diff / 1440);

    text = days + ' day(s)'
  }
  else if (diff >= 60) {
    const hours = Math.floor(diff / 60);

    text = hours + ' hour(s)'
  }
  else {
    if (diff < 1) {
      text = '< 1 min'
    }
    else {
      text = '~' + Math.round(diff) + ' min'
    }
  }

  return text;
}

/**
 * Encode slug
 */
export function encodeSlug(slug) {
  slug = slug == null ? '' : slug;
  return encodeURIComponent(slug.replaceAll(' ', '_'));
}