import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getDocumentCookie } from 'src/helpers';

export { RouteGuard };

function RouteGuard({ children }) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    authCheck();
  }, [router.asPath]);

  function authCheck() {
    if (process.browser) {
      // redirect to login page if accessing a private page and not logged in 
      const url = router.asPath;
      const publicPaths = ['/login', '/500', '/404'];
      const adminPaths = ['/admin'];
      const path = url.split('?')[0];
      const sessionId = getDocumentCookie('session_id', null);

      if (
        (
          sessionId == null &&
          !publicPaths.includes(path) &&
          !path.startsWith('/reset/') // Reset password + token
        ) ||
        (adminPaths.includes(path) && getDocumentCookie('tenant_role', null) != 'admin')
      ) {
        setAuthorized(false);

        router.push({
          pathname: '/login'
        });
      }
      else {
        setAuthorized(true);
      }
    }
  }

  return (authorized) ? children : null;
}
