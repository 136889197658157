import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/mobile-top-bar/mobile-top-bar.module.scss'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Icon from 'components/icon';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function MobileTopBar(props) {
  return (
    <div className={styles.mobileTopBar}>
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={props.onMenuClick}>
              <Icon name="menu"></Icon>
            </IconButton>

            {/* Breadcrumbs here */}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

MobileTopBar.propTypes = {
  onMenuClick: PropTypes.func,
}

export default MobileTopBar;