import RequestApi from 'lib/labelify-api';

/**
 * Get allergies
 */
export async function GetAllergies({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/allergies',
    sessionId
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get available units
 * @param {bool} volume 
 * @param {bool} mass 
 */
export async function GetAvailableUnits({ volume = null, mass = null, sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/available_units',
    body: {
      volume: volume,
      mass: mass,
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get yield units
 */
export async function GetYieldUnits({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/yield_units',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get concrete types
 */
export async function GetConcreteTypes({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/concrete_types',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get label rules
 */
export async function GetLabelRules({ concreteType = 'CAN2016', sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/label_rules/' + concreteType,
    sessionId,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get fop rules
 */
export async function GetFopRules({ concreteType = 'CAN2016', sessionId = null, responseHandler = null } = {}) {
  return {
    label_formats: {
      'bilingual_horizontal': {
        format_number: '1',
        name_en: 'Bilingual horizontal',
        name_fr: 'Horizontal bilingue',
        versions: ['1', '2', '3', '4', '5', '6']
      },
      'bilingual_vertical': {
        format_number: '1',
        name_en: 'Bilingual vertical',
        name_fr: 'Vertical bilingue',
        versions: ['3', '4', '5', '6']
      },
      'unilingual_horizontal': {
        format_number: '1',
        name_en: 'Unilingual horizontal',
        name_fr: 'Horizontal unilingue',
        versions: ['1', '2', '3', '4', '5', '6']
      },
      'unilingual_vertical': {
        format_number: '1',
        name_en: 'Unilingual vertical',
        name_fr: 'Vertical unilingue',
        versions: ['3', '4', '5', '6']
      },
    },
    display_surface_ranges: [
      {
        name_en: '> 600 cm<sup>2</sup>',
        name_fr: '> 600 cm<sup>2</sup>'
      },
      {
        name_en: '450 cm<sup>2</sup> to ≤ 600 cm<sup>2</sup>',
        name_fr: '450 cm<sup>2</sup> à ≤ 600 cm<sup>2</sup>'
      },
      {
        name_en: '> 250 cm<sup>2</sup> to ≤ 450 cm<sup>2</sup>',
        name_fr: '> 250 cm<sup>2</sup> à ≤ 450 cm<sup>2</sup>'
      },
      {
        name_en: '> 100 cm<sup>2</sup> to ≤ 250 cm<sup>2</sup>',
        name_fr: '> 100 cm<sup>2</sup> à ≤ 250 cm<sup>2</sup>'
      },
      {
        name_en: '> 30 cm<sup>2</sup> to ≤ 100 cm<sup>2</sup>',
        name_fr: '> 30 cm<sup>2</sup> à ≤ 100 cm<sup>2</sup>'
      },
      {
        name_en: '≤ 30 cm<sup>2</sup>',
        name_fr: '≤ 30 cm<sup>2</sup>'
      }
    ],
    nutrient_options: {
      'sat_fat_sugars_sodium': {
        name_en: 'Saturated fat (Sat fat), sugars and sodium',
        name_fr: 'Gras saturés (Gras sat.) sucres et sodium'
      },
      'sat_fat_sugars': {
        name_en: 'Saturated fat (Sat fat) and sugars',
        name_fr: 'Gras saturés (Gras sat.) et sucres'
      },
      'sugars_sodium': {
        name_en: 'Sugars and sodium',
        name_fr: 'Sucres et sodium'
      },
      'sat_fat_sodium': {
        name_en: 'Saturated fat (Sat fat) and sodium',
        name_fr: 'Gras saturés (Gras sat.) et sodium'
      },
      'sat_fat': {
        name_en: 'Saturated fat (Sat fat)',
        name_fr: 'Gras saturés'
      },
      'sugars': {
        name_en: 'Sugars',
        name_fr: 'Sucres'
      },
      'sodium': {
        name_en: 'Sodium',
        name_fr: 'Sodium'
      }
    }
  };
  const [responseData, response] = await RequestApi({
    url: 'utils/fop_rules/' + concreteType,
    sessionId,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get nutriment rules
 */
export async function GetNutrimentRules({ sessionId = null, responseHandler = null, former = false } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/nutriments_rules',
    sessionId,
    body: {
      former: former
    }
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get class names
 */
export async function GetClassNames({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/class_names',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get countries
 */
export async function GetCountries({ sortBy = null, sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/countries',
    body: {
      sort: sortBy
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get regions
 */
export async function GetRegions({ countryCode, sortBy = null, sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'utils/countries/' + countryCode + '/regions',
    body: {
      sort: sortBy
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Lang name
 * Helper function to resolve name vs name_en vs name_fr
 */
export function LangName(item, locale = 'en', key = 'name') {
  return item[key + '_' + locale];
}
